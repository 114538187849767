import React, { useEffect, useState } from 'react';
import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function MenuItem(props){
   return(
      <ListItem button={true} onClick={props.clickFunction} style={{minWidth:'200px'}} selected={props.selected}>
         <ListItemIcon style={{minWidth:'16px'}}>
            <FontAwesomeIcon icon={['far', props.icon]} color={'black'} size={'1x'} />
         </ListItemIcon>
         <ListItemText align={'center'} primary={props.text} />
      </ListItem>
   )
}
export function PersistentDrawer(props) {

   const gotoHref = (page) => {
      window.location.href = `/${page}`;
   }
   function MenuList() {
      const userType = localStorage.getItem('userType');
      if(userType === 'instructor')
         return (
            <div>
               <MenuItem text='Home' icon='home' clickFunction={() => gotoHref('') } selected={ props.pagePath ===""}/>
               <MenuItem text='Trainees' icon='user-friends' clickFunction={() => gotoHref('trainees')} selected={ props.pagePath ==="trainees"}/>
               <MenuItem text='Sessions' icon='user-clock' clickFunction={() => gotoHref('sessions')} selected={ props.pagePath ==="sessions"}/>
               {/* <MenuItem text='Reports' icon='file-alt' clickFunction={() => gotoHref('reports')} selected={selected ==="reports"}/> */}
            </div>
         )
      else if(userType === 'organization')
         return (
            <div>
               <MenuItem text='Home' icon='home' clickFunction={() => gotoHref('')} selected={ props.pagePath ===""} />
               {/* <MenuItem text='Modules' icon='wrench' clickFunction={() => gotoHref('modules')} selected={selected ==="modules"}/> */}
               <MenuItem text='Instructors' icon='bullhorn' clickFunction={() => gotoHref('instructors')} selected={ props.pagePath ==="instructors"}/>
               <MenuItem text='Trainees' icon='user-friends' clickFunction={() => gotoHref('trainees')} selected={ props.pagePath ==="trainees"}/>
               <MenuItem text='Sessions' icon='user-clock' clickFunction={() => gotoHref('sessions')} selected={ props.pagePath ==="sessions"}/>
               {/* <MenuItem text='Reports' icon='file-alt' clickFunction={() => gotoHref('reports')} selected={selected ==="reports"}/> */}
               {/* <MenuItem text='License Packages' icon='id-card' /> */}
            </div>
         )
      else return (
         <div>
               <MenuItem text='Sessions' icon='user-clock' clickFunction={() => { window.location.href = '/sessions' }} selected={ props.pagePath ==="sessions"}/>
         </div>
      )
   }
   return (
      <Drawer  anchor="left" variant="persistent" open={props.isOpen}>
         <List className="sidebar">
            {MenuList()}
         </List>
         <Button id="drawerCloseButton" size="small" variant="contained" onClick={() => props.toggleNavOpen()}> Close</Button>
      </Drawer>
   )
}
