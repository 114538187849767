const { createMuiTheme, makeStyles } = require('@material-ui/core');

exports.NexrLightTheme = createMuiTheme({
    palette: {
        background: {
           primary: "#FF0000"
        },
        text: {
           primary: "#000000"
        },
     },
     typography: {
        fontSize: 18,
        h1: {
           fontStyle: 'bold',
           fontSize: 24,
        },
        h2: {
           fontSize: 18,
           lineHeight: 1.5,
        },
     }
 });

this.cardStyle = makeStyles((theme) => ({
    root: {
       backgroundColor: "#f2f2f2",
       display: 'inline-block',
    },
    card: {
       alignSelf: 'flex-start',
       alignContent: 'center',
       padding: 12,
       height: 100,
       width: 100,
    }
 }))