import { Button, IconButton, Select, Menu, MenuItem,
    InputLabel, FormControl, } from "@material-ui/core";

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
   formControl: {
     minWidth: 180,
   },
 }));

export function FilterList (props) {
   const { filterOptions } = props;
   const [ menuOpen, setMenuOpen ] = useState(false);
   const [ filterAnchor, setFilterAnchor ] = useState(null);
   const [ optionFilters, setOptionFilters ] = useState([]);//example ["module", {Gas: "Gas"}]
   const [ selectValues, setSelectValues ] = useState([]);// example ["Gas", "Meter"]
   const [ optionKeyValues, setOptionKeyValues ] = useState({}); //example {module: "Gas"}
   const classes = useStyles();
   const openMenu = (e) =>{
      setMenuOpen(true);
      setFilterAnchor(e.target);
   }
   const closeMenu = () => {
      setMenuOpen(false);
      setFilterAnchor(null);
   }
   const removeOption = (optionFilter, selectIndex) => {
      let newOptFilters = [...optionFilters];
      let position = newOptFilters.indexOf(optionFilter);
      newOptFilters.splice(position,1);
      setOptionFilters(newOptFilters);

      let newSelectValues = [...selectValues];
      newSelectValues.splice(selectIndex, 1);
      setSelectValues(newSelectValues);

      let newOptionKeyValues = {...optionKeyValues};
      delete newOptionKeyValues[optionFilters[selectIndex][0]];
      setOptionKeyValues(newOptionKeyValues);
      props.setOptionKeyValues(newOptionKeyValues);
   }
   const addtoOptionFilters = (optionFilter) => {
      if(optionFilters.findIndex((option)=> option[0] === optionFilter[0]) === -1) {
         let newOptFilters = [...optionFilters];
         newOptFilters.push(optionFilter);
         setOptionFilters(newOptFilters);

         let newSelectValues = [...selectValues];
         newSelectValues.push('');
         setSelectValues(newSelectValues);
      }
   }
   const selectOptionValue = (e, selectIndex) => {
      const optionValue = e.target.value;
      let newSelectValues = [...selectValues];
      newSelectValues.splice(selectIndex,1,optionValue);
      setSelectValues(newSelectValues);

      let newOptionKeyValues = {...optionKeyValues};
      newOptionKeyValues[optionFilters[selectIndex][0]] = optionValue;
      setOptionKeyValues(newOptionKeyValues);
      props.setOptionKeyValues(newOptionKeyValues);
   }
   return (
      <div style={props.containerStyle||{}}>
         <Button variant={'outlined'} color={'primary'}
            style={props.buttonStyle}
            startIcon={<FontAwesomeIcon icon={['fas','filter']} style={{width:'0.8em', height:'0.8em'}}/>}
            onClick={(e) => openMenu(e)}>Filter</Button>
         <Menu anchorEl={filterAnchor}
            open={menuOpen}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={()=> closeMenu()}>
               {Object.entries(filterOptions).map((option) => 
               <MenuItem key={`key-${option[0]}`} onClick={()=>{
                  closeMenu();
                  addtoOptionFilters(option);}}>
                  {option[0]}
               </MenuItem>
               )}
         </Menu>
         <div style={{display:'inline-flex'}}>
         { optionFilters.length > 0 && 
         optionFilters.map((optionFilter, selectIndex) => 
            {  
               const optionKeyName = optionFilter[0];
               return(
                  <div key={`filter-${optionKeyName}`}>
                  <IconButton size={'small'} onClick={() => removeOption(optionFilter, selectIndex)}>
                     <FontAwesomeIcon icon={['far', 'times-circle']} size={'sm'}/>
                  </IconButton>
                  <FormControl className={classes.formControl}>
                     <InputLabel htmlFor={`${optionKeyName}-form`}>{optionKeyName}</InputLabel>
                        <Select inputProps={{id:`${optionKeyName}-form`}} onChange={(e) => selectOptionValue(e, selectIndex)}
                           value={selectValues[selectIndex]}>
                           {Object.entries(optionFilter[1]).map((optionKVP) => {
                              return(
                                 <MenuItem key={`${optionKeyName}-${optionKVP[0]}`} value={optionKVP[0]}>
                                 {optionKVP[1]}</MenuItem>
                              )
                           })}
                        </Select>
                  </FormControl>
                  </div>
               )
            }
         )}
         </div>

      </div>
   )
}