import React from 'react';
import { Avatar, Button, Card, CardActionArea,
    CardActions, CardContent, Typography} from '@material-ui/core';

export function InfoCard(props) {
    return (
        <Card id={props.header} className='infoPanel halfWidth'>
            <CardContent id={props.header + "_content"} >
                <CardActionArea>
                    <Typography variant="h1" gutterBottom>
                        {props.header}
                    </Typography>
                        {props.children}
                </CardActionArea>
                <CardActions className="infoButton">
                    {props.link!=null &&
                        <Button id="linkButton" className="infoButton" size="small" variant="contained" href={props.link} disableElevation> View All</Button>
                    }
                </CardActions>
            </CardContent>
        </Card>
    );
}

export function InfoCardFullwidth(props) {
    return (
        <Card id={props.header} className='infoPanel'>
            <CardContent id={props.header + "_content"} >
                <CardActionArea>
                    <Typography variant="h1" gutterBottom>
                        {props.header}
                    </Typography>
                    {props.children}
                </CardActionArea>
                <CardActions className="infoButton">
                    {props.link != null &&
                        <Button id="linkButton" className="infoButton" size="small" variant="contained" href={props.link} disableElevation> View All</Button>
                    }
                </CardActions>
            </CardContent>
        </Card>
    );
}

export function CurrentModulesContent(props) {
    return (
        <div>
            {props.modules.map(line => (
                <Typography variant="h2" key={line}>
                    {line}
                </Typography>
            ))}
        </div>
    )
}

export function SingleModuleContent(props) {
    return (
        <div>
                <Typography variant="h2">
                    {props.description}
                </Typography>
        </div>
    )
}

export function QuickStatsContent(props) {
    return (
        <div>
            <Typography variant="h2">Most used module: {props.stats.mostUsed}</Typography>
            <Typography variant="h2">Most Failed Test: {props.stats.mostFailed}</Typography>
            <Typography variant="h2">Overall Average Score: {props.stats.overallAverageScore}</Typography>
            <Typography variant="h2">Average score improvement: {props.stats.improvement}</Typography>
            <Typography variant="h2">Highest scoring module: {props.stats.highestScore}</Typography>
            <Typography variant="h2">Lowest scoring module: {props.stats.lowestScore}</Typography>
        </div>
    )
}

export function AccountInfoContent(props) {
    return (
        <div>
            <Typography variant="h2">Organization: {props.organization.name}</Typography>
            <Typography variant="h2">Product Key: {props.organization.key}</Typography>
            <Typography variant="h2">Workstations: {props.organization.maxWorkstations}</Typography>
            <Typography variant="h2">Max Active Trainees: {props.organization.seats}</Typography>
            <Typography variant="h2">Expiration: {props.organization.expiration}</Typography>
        </div>
    )
}

export function AnnouncementInfoContent(props) {
    return (
        <div>
            <Avatar className="infoAvatar" style={{ width: 60, height: 60 }}>
                <i className="fa     fa-bullhorn" style={{ fontSize: 40 }}></i>
            </Avatar>
            {props.announcements.length == 0 &&
                <div>
                <Typography variant="h2" style={{ fontSize: 24 }}>
                        You're all caught up!
                            </Typography>
                <Typography style={{ fontSize: 16 }}>
                        New announcements will display here.
                            </Typography>
                </div>
            }
            {props.announcements.length > 0 &&
                props.announcements.map(line => (
                    <Typography variant="h2" key={line}>
                        {line}
                    </Typography>
                ))
            }
        </div>
        )
}