import React from 'react';
import { Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Chart from 'chart.js';
import ReactDOM from 'react-dom'
import { InfoCard, AccountInfoContent, AnnouncementInfoContent, CurrentModulesContent, QuickStatsContent, InfoCardFullwidth } from './infoCard';
import * as StatsHelper from './components/statsHelper.js';
import * as MathHelper from './components/mathHelper.js';
import {PageTemplate} from './pageTemplate.jsx';
import { EmployeeSearch } from './employeeSearch.jsx';
import * as Utils from './Utils';
import {FilterList} from './components/filterList.jsx';
import {DateRange} from './components/dateRange.jsx';
class IndexPage extends React.Component {
   constructor(props) {
      super(props);
      this.todayDate = MathHelper.getTodayDate();
      this.state = {
         open: true,
         currentUser: {firstName: '', lastName: ''},
         curOrganization: {},
         trainees: [],
         sessions: [],
         instructors: [],
         stats : {mostFailedTask:""},
         startDate: "",
         endDate:"",
         filters:{},
      };
   }

   async componentDidMount() {
      let {trainees, currentUser, curOrganization, sessions, instructors} = await Utils.getCommonUserInfo();
      this.statsHelper = new StatsHelper({sessions});
      const stats = await this.statsHelper.calcStats();
      this.setState({trainees, currentUser, curOrganization, sessions, instructors, stats});
      try {
         let ctxChart = document.getElementById('statsChartCanvas');

         let data = {
            labels: stats.averageScores.labels,
            datasets: [{
               label: 'Average Score (%)',
               data: stats.averageScores.data,
               backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
               ],
               borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
               ],
               borderWidth: 1
            }]
         };

         let options = {
            maintainAspectRatio: false,
            scales: {
               yAxes: [{
                  stacked: true,
                  ticks: {
                     stepSize: 25,
                  },
                  gridLines: {
                     display: true,
                     color: "rgba(255,99,132,0.2)"
                  }
               }],
               xAxes: [{
                  gridLines: {
                     display: false
                  }
               }]
            },
            tooltips: {
               enabled: true,
            }
         };



         this.barChart = new Chart(ctxChart, {
            type: 'line',
            data,
            options
         })

         ctxChart.onmousemove = (e) => {
            let elements = this.barChart.getElementsAtEvent(e);
            if (elements.length > 0) {
               // console.log(elements);
            }
         }
      }
      catch (e) {
         console.log(e);
      }
   }
   async setStartDate(startDate) {
      if(new Date(startDate).toString() === "Invalid Date"){
         this.setState({startDate})
      }
      else {
         const {endDate, sessions, filters} = this.state;
         this.statsHelper.reset({sessions, startDate, endDate, filters});
         const stats = await this.statsHelper.calcStats();
         this.barChart.data.datasets[0].data = stats.averageScores.data;
         this.barChart.data.labels = stats.averageScores.labels;
         this.barChart.update();
         this.setState({stats,startDate});
      }
   }
   async setEndDate(endDate) {
      if(new Date(endDate).toString() === "Invalid Date")
         this.setState({endDate})
      else {
         const {startDate, sessions, filters} = this.state;
         this.statsHelper.reset({sessions, startDate, endDate, filters});
         const stats = await this.statsHelper.calcStats();
         this.barChart.data.datasets[0].data = stats.averageScores.data;
         this.barChart.data.labels = stats.averageScores.labels;
         this.barChart.update();
         this.setState({stats, endDate});
      }
   }
   async setFilters(filterOptions) {
      const {sessions, startDate, endDate} = this.state;
      this.statsHelper.reset({sessions, startDate, endDate, filters: filterOptions});
      const stats = await this.statsHelper.calcStats();
      this.barChart.data.datasets[0].data = stats.averageScores.data;
      this.barChart.data.labels = stats.averageScores.labels;
      this.barChart.update();
      this.setState({stats, filters: filterOptions});
   }
   toggleNavOpen(){
      let newWidth = this.state.isNavOpen ? '0px': '260px';
      document.getElementById("reactRoot").style.setProperty('--sidebar-width', newWidth);
      this.setState({isNavOpen: !this.state.isNavOpen})
   }

   render() {
      const { currentUser, curOrganization, endDate, startDate, stats, instructors, trainees } = this.state;
      let employees = instructors.concat(trainees).sort((a, b) => (a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1 : -1);;
      return (
         <PageTemplate user={currentUser} pagePath={""}>
               <div className='headerInfoRow'>
                  <div className='headerText halfWidth'>
                     Welcome, {currentUser.firstName}!
                  </div>
               </div>
               <div className='infoPanelRow'>
                  <EmployeeSearch employees={employees} />
                     <div className={'columnRight'}>
                        <DateRange todayDate={MathHelper.getTodayDate()}
                                   setStartDate={(value)=> this.setStartDate(value)}
                                   setEndDate={(value)=> this.setEndDate(value)}/>
                     </div>
               </div>
               <Paper className='infoPanel infoPanelRow'>
                  <div style={{ position: 'relative', width: '100%', height: '200px'}}>
                     <canvas id='statsChartCanvas'>
                     </canvas>
                  </div>
               </Paper>
               <div className="infoPanelRow">
                  <FilterList filterOptions={stats.filterOptions || {}}
                  containerStyle={{display:'flex'}}
                  setOptionKeyValues={(filterOptions) => this.setFilters(filterOptions)}/>
               </div>
               { this.state.curOrganization ?
               <div className='infoPanelRow'>
                  <InfoCard header='Quick Stats' link="./stats">
                     <QuickStatsContent stats={{
                        mostUsed: "Gas - Interior Leak Training",
                        mostFailed: stats.mostFailedTask.name,
                        overallAverageScore: stats.overallAverageScore,
                        improvement: "+15%",
                        highestScore: "Gas - Yard Leak",
                        lowestScore: "Gas - Street Leak"
                     }} />
                  </InfoCard>
                  <InfoCard header='Account Information' link='./account'>
                     <AccountInfoContent organization={this.state.curOrganization} />
                  </InfoCard>
               </div> :
               <div className='infoPanelRow'>
                  <InfoCardFullwidth header='Quick Stats' link="./stats">
                     <QuickStatsContent stats={{
                        mostUsed: "Gas - Interior Leak Training",
                        mostFailed: this.state.stats.mostFailedTask.name,
                        improvement: "+15%",
                        highestScore: "Gas - Yard Leak",
                        lowestScore: "Gas - Street Leak"
                     }} />
                  </InfoCardFullwidth>
               </div>
               }
         </PageTemplate>

      )
   }
}

window.onload = async function(){
   let authToken = window.localStorage.getItem('authToken')
   if(!authToken){
      window.location = '/login'
   }
   ReactDOM.render(
      <IndexPage />,
      document.getElementById('reactContainer')
   );
};
