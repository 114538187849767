import "regenerator-runtime/runtime";
import React, { useState } from 'react';
import {Avatar , Button, IconButton, Menu, MenuItem, Paper} from '@material-ui/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function TopBar(props) {
   return (
      <Paper id='topBar'>
         <IconButton onClick={() => props.toggleNavOpen()}>
            <FontAwesomeIcon icon={['far', "bars"]} color={'white'} size={'1x'} />
         </IconButton>
         <img src={'/assets/logo.png'} className="top-bar-logo" />
         <SimpleMenu id={'topBarUserDropdown'} user={props.user} >
         </SimpleMenu>
      </Paper>
   )
}

function SimpleMenu(props) {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const logout = () => {
      window.localStorage.removeItem('authToken');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('userOrganizationId');
      window.localStorage.removeItem('userType');
      window.localStorage.removeItem('userTitle');
      window.location = '/login';
   }

   let firstName = props.user.firstName || '';
   let lastName = props.user.lastName || '';

   return (
      <div className="topBarRight">
         <Avatar className="topBarAvatar">
            {firstName.substring(0,1)}{lastName.substring(0,1)}
         </Avatar>
         <Button className="whiteText top-bar-text" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
            {firstName} {lastName}
         </Button>
         <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            <MenuItem onClick={() => {window.location.href = '/account'}}>My account</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
         </Menu>
      </div>
   );
}
