//const baseURL = (typeof (window) !== 'undefined' ? window.location.origin : null);
//const baseURL = 'http://localhost:3000'
//const baseURL = window.location.protocol + '//nexr.herokuapp.com';
const baseURL = window.location.origin;
import * as Ajax from './ajax';

let token = localStorage.getItem('authToken');
let userType = localStorage.getItem('userType');
let userId = localStorage.getItem('userId');

function appendTokenString(input){
   let urlSeparator = '?';
   if(input.includes('?')){
      urlSeparator = '&';
   }
   return input + urlSeparator + 'access_token=' + token;
}
export function loginAdmin(email, password){
   return Ajax.post(`${baseURL}/api/admins/login`, {email, password});
}

export function loginInstructor(email, password){
   return Ajax.post(`${baseURL}/api/instructors/login`, {email, password});
}

export function loginOrganization(email, password){
   return Ajax.post(`${baseURL}/api/organizations/login`, {email, password});
}

export function getInstructorInfo(idInstructor){
   if(userType === 'admin')
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`));
   else if(userType === 'organization')
      return Ajax.get(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors/${idInstructor}`));
   else if(userType === 'instructor')
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`));
   else return null;
}

export function getOrganizationInfo(idOrganization){
   return Ajax.get(appendTokenString(`${baseURL}/api/organizations/${idOrganization}`));
}

export function getTraineesForInstructor(idInstructor){
   if(userType === 'admin')
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}/trainees`));
   else if(userType === 'organization')
      return orgGetTraineesForInstructor(idInstructor);
   else if(userType === 'instructor'){
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}/trainees`));
   }

   return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}/trainees`));
}

export function orgGetTraineesForInstructor(idInstructor){
   return Ajax.get(appendTokenString(`${baseURL}/apiv2/organizations/${userId}/instructors/${idInstructor}/trainees`));
}

export function getAllSessions(){
   if(userType === 'admin')
      return Ajax.get(appendTokenString(`${baseURL}/api/sessions`));
   else if(userType === 'organization'){
      return getAllSessionsForOrg();
   }
   else if(userType === 'instructor') {
      return getAllSessionsForInstructor();
   }
}

export function getAllSessionsForOrg(){
   return Ajax.get(appendTokenString(`${baseURL}/apiv2/organizations/${userId}/sessions`))
}

export function getAllSessionsForInstructor() {
   return Ajax.get(appendTokenString(`${baseURL}/apiv2/instructors/${userId}/sessions`))
}

export function getInstructors(){
   if(userType === 'admin')
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors`));
   else if(userType === 'organization'){
      return Ajax.get(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors`));;
   }
   else if(userType === 'instructor') {
      return null;
   }
}

export function getSessionsForTrainee(idTrainee){
   return Ajax.get(appendTokenString(`${baseURL}/api/trainees/${idTrainee}/sessions`));
}

export function getTraineeById(idTrainee){
   if(userType === 'admin')
   return Ajax.get(appendTokenString(`${baseURL}/api/trainees/${idTrainee}`));
   else if(userType === 'organization')
      return Ajax.get(appendTokenString(`${baseURL}/apiv2/organizations/${userId}/trainees/${idTrainee}`));
   else if(userType === 'instructor')
      return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${userId}/trainees/${idTrainee}`));
   else
      return Ajax.get(appendTokenString(`${baseURL}/api/trainees/${idTrainee}`));
}

export function getInstructorsForOrganization(idOrganization){
   return Ajax.get(appendTokenString(`${baseURL}/api/organizations/${idOrganization}/instructors`));
}

export function getInstructorById(idInstructor){
   return Ajax.get(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`));
}

export function changeUserPassword(idOrganization, password){
   return Ajax.patch(appendTokenString(`${baseURL}/api/organizations/${idOrganization}`),{password});
}

export function changeOrgInfo(idOrganization, firstName, lastName, email){
   return Ajax.patch(appendTokenString(`${baseURL}/api/organizations/${idOrganization}`),
      {firstName, lastName, email});
}

export function changeInstructorInfo(idInstructor, firstName, lastName, email, seats) {
   if(userType === 'admin')
      return Ajax.patch(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`), { firstName, lastName, email, seats });
   else if(userType === 'organization')
      return Ajax.put(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors/${idInstructor}`),
      { firstName, lastName, email, seats });
   else if(userType === 'instructor')
      return Ajax.patch(appendTokenString(`${baseURL}/api/instructors/${userId}`), { firstName, lastName, email, seats });
   else
      return null;
}

export function changeInstructorPassword(idInstructor, password){
   return Ajax.patch(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`),{password});
}

export function createInstructor(firstName, lastName, email, seats){
   if(userType === 'admin'){
      return Ajax.post(appendTokenString(`${baseURL}/api/instructors`), {firstName, lastName, email, seats});
   }
   else if(userType === 'organization'){
      return Ajax.post(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors`),
         {firstName, lastName, email, seats});
   }
   else if(userType === 'instructor')
      throw 'Instructors cannot create other instructors';
   else {
      throw 'Not Logged In';
   }
}
export function createTrainee(idInstructor, firstName, lastName, active) {
   if(userType === 'admin')
      return Ajax.post(appendTokenString(`${baseURL}/api/instructors/${idInstructor}/trainees`),{firstName, lastName, active});
   else if(userType === 'organization')
      return Ajax.post(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors/${idInstructor}/trainees`),{firstName, lastName, active});
   else if(userType === 'instructor')
      return Ajax.post(appendTokenString(`${baseURL}/api/instructors/${userId}/trainees`),{firstName, lastName, active});
   else return null;
}

export function changeTraineeInfo( idTrainee, idInstructor, firstName, lastName, active) {
   if(userType === 'admin')
      return Ajax.patch(appendTokenString(`${baseURL}/api/trainees/${idTrainee}`),{ firstName, lastName, active });
   else if(userType === 'organization')
      return Ajax.put(appendTokenString(`${baseURL}/apiv2/organizations/${userId}/instructors/${idInstructor}/trainees/${idTrainee}`),{firstName, lastName, active});
   else if(userType === 'instructor')
      return Ajax.put(appendTokenString(`${baseURL}/api/instructors/${userId}/trainees/${idTrainee}`),{firstName, lastName, active});
   else return null;
}
export function deleteTrainee (idTrainee) {
   if(userType === 'admin')
      return Ajax.del(appendTokenString(`${baseURL}/api/trainees/${idTrainee}`))
   else if(userType === 'organization')
      return Ajax.del(appendTokenString(`${baseURL}/apiv2/organizations/${userId}/trainees/${idTrainee}`))
   else if(userType === 'instructor')
      return Ajax.del(appendTokenString(`${baseURL}/api/instructors/${userId}/trainees/${idTrainee}`))
   else return null;
}
export function deleteInstructor(idInstructor) {
   if(userType === 'admin')
      return Ajax.del(appendTokenString(`${baseURL}/api/instructors/${idInstructor}`))
   else if(userType === 'organization')
      return Ajax.del(appendTokenString(`${baseURL}/api/organizations/${userId}/instructors/${idInstructor}`));
   else if(userType === 'instructor')
      return null;
   else return null;
}
