export function toHHMMSS  (secondValue) {
   var hours   = Math.floor(secondValue / 3600);
   var minutes = Math.floor((secondValue - (hours * 3600)) / 60);
   var seconds = secondValue - (hours * 3600) - (minutes * 60);
   if (hours   < 10) {hours   = "0"+hours;}
   if (minutes < 10) {minutes = "0"+minutes;}
   if (seconds < 10) {seconds = "0"+seconds;}
   return hours + ':' + minutes + ':' + seconds;
}
export function percentageOf  ( value , total) {
   return (value / total * 100).toFixed(2);
}
export function getTodayDate () {
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = today.getFullYear();
   today = `${yyyy}-${mm}-${dd}`;
   return today;
}