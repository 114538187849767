import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText,
  DialogActions, Table, TableContainer, TableRow, TableCell, TextField, Paper} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js'

export function EmployeeSearch (props) {
    let { employees } = props;
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const gotoPage = (personPage) => {
        if(personPage.organizationId) window.location = '/instructor/' + personPage.id;
        else window.location = '/trainee/' + personPage.id;
    }
    let personFuseSearch = new Fuse(employees, {
      includeScore: false,
      ignoreLocation: true,
      threshold: 0.0,
      keys: ['lastName', { name:'firstName', weight: 2}]});
    let personSearchResult = (searchText == '') ? [] : personFuseSearch.search(searchText).map(cur => cur.item);
    return (
        <div>
          <div style={{marginBottom:'1em'}}>
            <Button variant="outlined" onClick={() => setSearchOpen(true)}
              endIcon={<FontAwesomeIcon icon={['far','search']} color='grey'/>}>Search Employee</Button>
          </div>
          <Dialog open={searchOpen}>
            <DialogContent>
              <DialogContentText component='h2'>Search Employee</DialogContentText>
                <TextField fullWidth variant={'outlined'} label={'Enter Name'} onChange={(e) => setSearchText(e.target.value)}/>
                <Paper elevation={0} style={{width:'400px', height:'400px', overflowY:'auto'}}>
                  <Table>
                    {personSearchResult.length > 0 ? personSearchResult.map((result) => {
                        return (
                          <TableRow key={result.id} onClick={() => gotoPage(result)} className={'clickable-row'}>
                            <TableCell>{`${result.firstName} ${result.lastName}`}</TableCell>
                            <TableCell>{result.organizationId? 'Instructor' : 'Trainee'}</TableCell>
                          </TableRow>
                        )
                    }) :
                    <caption>No match found</caption>}
                  </Table>
                </Paper>
              <DialogActions>
                <Button color={'primary'} onClick={() => setSearchOpen(false)} >Close</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>

    )
}