class StatsHelper {
    constructor(params) {
        this.reset(params);
    }
    reset (params) {
        let{ sessions, startDate, endDate, filters = {}} = params;
        this.sessions = sessions;
        this.filteredSessions = [];
        this.startDate = new Date(startDate || "").getTime();
        this.endDate = new Date(endDate || "").getTime();
        this.filters = filters;
        this.moduleTypes = [];
        this.scenarioSituations = [];
        this.scenarioTypes = [];
        this.failTasks = {};
        this.scores = [];
    }
    async calcStats () {
        await this.iterateSessions ();
        let mostFailedTask = this.getMostFailedTask ();
        let averageScores = this.getAverageScoreOfEachMonth();
        let overallAverageScore = this.getOverallAverageScore();
        let filterOptions = {module:{}, scenarioSituation:{}, scenarioType:{}};
        this.moduleTypes.forEach((moduleType) => {filterOptions.module[moduleType] = moduleType;});
        this.scenarioSituations.forEach((scenarioSituation) => {filterOptions.scenarioSituation[scenarioSituation] = scenarioSituation;});
        this.scenarioTypes.forEach((scenarioType) => {filterOptions.scenarioType[scenarioType] = scenarioType;});
        return {mostFailedTask, averageScores, overallAverageScore, filterOptions, filteredSessions:this.filteredSessions};
    }
    async iterateSessions () {
        let MathHelper = await import ('./mathHelper.js');
        this.sessions.sort((a,b) => new Date(b.created) - new Date(a.created));// old to new
        const dateLimitValid = !isNaN(this.startDate) || !isNaN(this.endDate);

        for(let session of this.sessions) {
            const sessionDate = new Date(session.created).getTime();
            if(dateLimitValid && (sessionDate> this.endDate || sessionDate < this.startDate )) continue;

            if(this.filters.module && this.filters.module !== session.module)continue;
            if(this.filters.scenarioSituation && this.filters.scenarioSituation !== session.scenarioSituation) continue;
            if(this.filters.scenarioType && this.filters.scenarioType !== session.scenarioType) continue;

            session.taskStatus = {};

            if(!this.moduleTypes.includes(session.module))
                this.moduleTypes.push(session.module);
            if(!this.scenarioSituations.includes(session.scenarioSituation))
                this.scenarioSituations.push(session.scenarioSituation);
            if(!this.scenarioTypes.includes(session.scenarioType))
                this.scenarioTypes.push(session.scenarioType);

            if(session.incompleteTasks.length !== 0){
                for(let incompleteTask of session.incompleteTasks) {
                   if(incompleteTask.tasks) {
                      for (let task of incompleteTask.tasks) {
                         session.taskStatus[`${task.name}`] = "Fail";
                         if (this.failTasks[`${incompleteTask.sequenceName} - ${task.name}`])
                            this.failTasks[`${incompleteTask.sequenceName} - ${task.name}`].count += 1;
                         else
                            this.failTasks[`${incompleteTask.sequenceName} - ${task.name}`] = { count: 1 }
                      }
                   }
                }
            }

            if(session.completeTasks.length !== 0){
                for(let completeTask of session.completeTasks) {
                   if(completeTask.tasks) {
                      for (let task of completeTask.tasks) {
                         session.taskStatus[`${task.name}`] = "Pass";
                      }
                   }
                }
            }
            this.filteredSessions.push(session);
            this.scores.push({date: session.created ,score: parseFloat(MathHelper.percentageOf(session.score, session.maxScore))});
        }
    }
    getMostFailedTask () {
        let highestCountItem = {name:"N/A", count: -1};
        for(const [key, value] of Object.entries(this.failTasks)) {
            if(highestCountItem.count < value.count)
            {
                highestCountItem.name = key;
                highestCountItem.count = value.count;
            }
        }
        return highestCountItem;
    }
    getAverageScoreOfEachMonth() {
        let allScoresEachMonth = [];
        const dateLongOptions = { year: 'numeric', month: '2-digit' };
        const dateTagOptions = { year: '2-digit', month: '2-digit' };
        // chunk scores to each month
        for (const score of this.scores) {
            const scoreDate = new Date(score.date);
            const dateLong = scoreDate.toLocaleDateString(undefined,dateLongOptions);
            const existingIndex = allScoresEachMonth.findIndex((x) => x.dateLong === dateLong);
            if(existingIndex > -1){
                allScoresEachMonth[existingIndex].scores.push(score.score)
            }
            else allScoresEachMonth.push ({dateFull: score.date, dateLong, scores : [score.score]})
        }
        allScoresEachMonth.reverse();
        // get average score of each available month
        for(let scoreEachMonth of allScoresEachMonth) {
            let total = 0;
            scoreEachMonth.scores.forEach(score => total += score);
            scoreEachMonth.averageScore = total / scoreEachMonth.scores.length;
        }
        let prevDate;
        let prevAvg;
        // if any missing months fill in the average score
        for(let scoreEachMonth of allScoresEachMonth) {
            let monthIndex = allScoresEachMonth.indexOf(scoreEachMonth);
            let monthsBetween = 0;
            let previousDate;
            let currentDate;
            if(prevDate) {
                currentDate = new Date(scoreEachMonth.dateFull);
                previousDate = new Date(prevDate);
                monthsBetween = (currentDate.getFullYear() - previousDate.getFullYear()) * 12 + (currentDate.getMonth() - previousDate.getMonth());
            }
            // if there is gap between month fill in with average between last month and curr month
            if(monthsBetween > 1) {
                let scoreDifference = scoreEachMonth.averageScore - prevAvg;
                let scoreStep = scoreDifference / (monthsBetween);
                for (let i = 1; i < monthsBetween ; i++) {
                    let spliceIndex = monthIndex + (i-1);
                    let filledInDate = new Date();
                    if(previousDate.getMonth() + i > 11){
                        filledInDate.setMonth(0);
                        filledInDate.setFullYear(previousDate.getFullYear() + 1);
                    }
                    else {
                        filledInDate.setMonth(previousDate.getMonth() + i);
                        filledInDate.setFullYear(previousDate.getFullYear());
                    }
                    let filledInMonth = {dateLong: filledInDate.toLocaleDateString(undefined,dateLongOptions), averageScore: prevAvg + (scoreStep * i)}
                    allScoresEachMonth.splice(spliceIndex,0,filledInMonth);
                }
            }
            prevAvg = scoreEachMonth.averageScore;
            prevDate = scoreEachMonth.dateFull;
        }
        // get final output
        let averageScores = {data:[],labels:[]};
        allScoresEachMonth.forEach(scoreEachMonth => {
            averageScores.data.push(scoreEachMonth.averageScore);
            averageScores.labels.push(scoreEachMonth.dateLong);
        });

        return averageScores;
    }
    getOverallAverageScore () {
        let total = 0;
        this.scores.forEach((score) =>  total += score.score );
        let avgScore = total / this.scores.length;
        if(isNaN(avgScore)) return "N/A";
        else return `${(total / this.scores.length).toFixed(2)}%`;
    }
}
module.exports = StatsHelper;
