import React, { useState } from 'react';
import { TextField, Typography} from '@material-ui/core';

export function DateRange (props) {
   const [startDate, setStart ] = useState('');
   const [endDate, setEnd ] = useState('');
   const setStartDate = (value) => {
      setStart(value);
      if(props.setStartDate) props.setStartDate(value);
   }
   const setEndDate = (value) => {
      setEnd(value);
      if(props.setEndDate) props.setEndDate(value);
   }
   return (
      <div style={props.style||{}}className='infoPanelRow'>
         <Typography style={{margin:'auto 0'}}>Date Range</Typography>
         <TextField style={{marginLeft:'1em'}} label={'Start Date'} variant={'outlined'} type={'date'}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }} InputProps={{inputProps:{max : endDate}}}/>
         <TextField style={{marginLeft:'1em'}} label={'End Date'} variant={'outlined'} type={'date'}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }} InputProps={{inputProps:{min : startDate, max: props.todayDate}}}/>
      </div>
   )
}