import * as AjaxFunctions from "./instructorAjaxFunctions";

export async function getCommonUserInfo(params){
   const userType = window.localStorage.getItem('userType');
   let userObj;
   if(userType === 'instructor') {
      userObj = await getInstructorUser(params);
   }
   else if(userType === 'organization'){
      userObj = await getOrganizationUser(params);
   }
   else {
      userObj = await getTraineeUser(params);
   }
   return userObj;
}

async function getInstructorUser(params) {
   let {traineeId} = params ? {...params} : {};
   let currentUser = await AjaxFunctions.getInstructorInfo(localStorage.getItem('userId'));
   let trainees = await AjaxFunctions.getTraineesForInstructor(localStorage.getItem('userId'));
   if(traineeId) trainees = trainees.filter(trainee => trainee.id === traineeId);
   let sessions = await AjaxFunctions.getAllSessions();
   if(traineeId) sessions = sessions.filter(session => session.traineeId === traineeId)
   sessions.forEach((session) => {
      let curTrainee = trainees.find(trainee => trainee.id === session.traineeId)
      session["traineeName"] = `${curTrainee.firstName} ${curTrainee.lastName}`;
      session["instructorName"] = `${currentUser.firstName} ${currentUser.lastName}`;
   })

   let curOrganization = null;
   let instructors = [];
   return {trainees, currentUser, curOrganization, sessions, instructors};
}

async function getOrganizationUser(params) {
   let {traineeId} = params ? {...params} : {};
   const organizationId = window.localStorage.getItem('userOrganizationId');
   let curOrganization = await AjaxFunctions.getOrganizationInfo(organizationId);
   let instructors = await AjaxFunctions.getInstructorsForOrganization(organizationId);

   let nestedTrainees = await Promise.all(instructors.map( async(instructor) => {
      let trainees = await AjaxFunctions.getTraineesForInstructor(instructor.id);
      trainees.forEach((trainee) => trainee["instructorName"] = `${instructor.firstName} ${instructor.lastName}`);
      return trainees; }));
   let trainees = nestedTrainees.flat();
   if(traineeId) trainees = trainees.filter(trainee => trainee.id === traineeId);

   let sessions = await AjaxFunctions.getAllSessions();
   if(traineeId) sessions = sessions.filter(session => session.traineeId === traineeId)
   sessions.forEach((session) => {
      let curTrainee = trainees.find(trainee => trainee.id === session.traineeId)
      let curInstructor = instructors.find(instructor => instructor.id === curTrainee.instructorId)
      session["traineeName"] = `${curTrainee.firstName} ${curTrainee.lastName}`;
      session["instructorName"] = `${curInstructor.firstName} ${curInstructor.lastName}`;
   })
   let currentUser = {
      firstName: curOrganization.firstName || curOrganization.name || '',
      lastName: curOrganization.lastName || ''
   }
   return {trainees, currentUser, curOrganization, sessions, instructors};
}

async function getTraineeUser (params) {
   let trainees = [];
   let instructors = [];
   let sessions = [];
   let curOrganization = null;
   let currentUser = {firstName: '', lastName: ''};
   return {trainees, currentUser, curOrganization, sessions, instructors};
}

export async function getTrainees () {
   let trainees = [];
   const userType = window.localStorage.getItem('userType');
   const organizationId = window.localStorage.getItem('userOrganizationId');
   if(userType === 'instructor') {
      trainees = await AjaxFunctions.getTraineesForInstructor(localStorage.getItem('userId'));
   }
   else if(userType === 'organization'){
      let instructors = await AjaxFunctions.getInstructorsForOrganization(organizationId);
      let unmergedTrainees = await Promise.all(instructors.map( async(instructor) => await AjaxFunctions.getTraineesForInstructor(instructor.id)))
      trainees = unmergedTrainees.flat();
   }
   return {trainees};
}
