import React from 'react';
import { TopBar } from './topBar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faBars, faWrench, faBullhorn, faUserFriends, faFileAlt,
   faIdCard, faCheck, faUserClock, faPlus, faTimesCircle, faSearch ,faCaretDown} from '@fortawesome/pro-regular-svg-icons';
import {faFilter, faTrash, faPencil} from '@fortawesome/pro-solid-svg-icons';
import { PersistentDrawer } from "./sidebar";
library.add(faBars, faHome, faWrench, faBullhorn, faUserFriends, faFileAlt, faIdCard,
   faCheck, faUserClock, faPencil, faTrash, faPlus, faTimesCircle, faFilter, faSearch, faCaretDown);
import styles from './styles.js'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
export class PageTemplate extends React.Component {
   constructor(props) {
      super(props);
      this.theme = styles.NexrLightTheme;
      this.state = {
         open: true,
         isNavOpen: true,
      };
   }

   componentDidMount() {
      let token = localStorage.getItem('authToken');
      if(!token){
         window.location = '/login';
      }
   }

   toggleNavOpen(){
      let newWidth = this.state.isNavOpen ? '0px': '200px';
      document.getElementById("reactRoot").style.setProperty('--sidebar-width', newWidth);
      this.setState({isNavOpen: !this.state.isNavOpen})
   }

   render() {
      return (
         <MuiThemeProvider theme={this.theme} >
            <div id='reactRoot'>
               <TopBar toggleNavOpen={() => this.toggleNavOpen()} user={this.props.user} />
               <PersistentDrawer pagePath={this.props.pagePath} isOpen={this.state.isNavOpen} toggleNavOpen={() => this.toggleNavOpen()}>
               </PersistentDrawer>
               <div id='curPageContainer'>
                  {this.props.children}
               </div>
            </div>
            </MuiThemeProvider>
      )
   }
}
